.contentWrapper {
  margin: 0 0 16px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #b4b4b4;
  min-height: 28px;
  padding: 30px;
}

.radioWrapper {
  display: flex;
  align-items: center;
}

.radioLabel {
  padding-left: 10px;
  cursor: pointer;
  font-weight: normal;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  width: 18px;
  height: 18px;
}

.checkmark::before {
  content: '';
  background-position: 0 -16px;
  background-image: url(core/assets/sp_form.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: inline-block;
}

.checkbox:checked + .checkmark::before {
  background-position: -18px -16px;
}

.btnAdd {
  box-sizing: border-box;
  width: 56px;
  height: 22px;
  padding: 0 10px;
  font-size: 10px !important;
  font-weight: 700;
  color: #594b4f;
  background: linear-gradient(to bottom, #fbfbfb 0, #ebebeb 100%);
  border: 1px solid #b4b4b4;
}

.inputLabel {
  display: flex;
}

.usersSelectionWrapper {
  display: flex;
  padding: 28px 11px 0 11px;
}

.usersSelection {
  width: 100%;
  border: 1px solid #a49c9e;
}

.titleWrapper {
  text-align: center;
  font-weight: 700;
  padding: 10px;
  color: var(--background-color-3);
  background: var(--neutral-color-4);
}

.userList {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 244px;
}

ul {
  padding: 0;
}

.user {
  cursor: pointer;
  list-style: none;
  height: 35px;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid #ebebeb;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 9px 12px 0;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding: 11px 12px;
  border-top: 1px solid #ebebeb;
}

.moreBtn {
  min-width: 134px;
}

.selectedUserItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnDelete {
  width: 10px;
  height: 14px;
  background-image: url(core/assets/icon_delete.png);
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btnApply {
  width: 134px;
}
