@import-normalize; /* bring in normalize.css styles */

html,
body,
#root,
#root > div {
  height: 100vh;
}

body {
  margin: 0;
  background: var(--background-color-1);
  font-family: Roboto, 'Nanum Gothic', 돋움, Dotum, 굴림, Gulim, sans-serif;
  font-size: 12px;
}

:root {
  /* Colors */
  --primary-color: #73c8cd;
  --primary-color-2: #baeeec;
  --primary-color-dark: #19a5a0;
  --secondary-color: #f47971;
  --secondary-color-dark: #e06162;
  --neutral-color-1: #5a5a5a;
  --neutral-color-2: #b4b4b4;
  --neutral-color-3: #464646;
  --neutral-color-4: #a49c9e;
  --neutral-color-5: #594b4f;
  --neutral-color-6: #e6e6e6;
  --neutral-color-7: #dad8d8;
  --neutral-color-8: #6e6e6e;
  --neutral-color-9: #ebebeb;
  --neutral-color-10: #595959;
  --neutral-color-11: #91bf91;
  --neutral-color-12: #ececec;
  --background-color-1: #dcd6d2;
  --background-color-2: #f4f3f2;
  --background-color-3: #ffffff;
  --background-color-4: #eeeded;
  --font-color: #333333;
  --font-color-2: #34b0ac;
  --error-color: #f44336;
  --info-color: #29b6f6;
}

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
@import '~antd/dist/antd.css';

/* FONT FACES */

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_cyrillic_ext-822818d7eb.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_cyrillic-9a5652bd5d.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_greek_ext-7de94a25c3.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_greek-5042ee3612.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_vietnamese-cdca794477.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_latin_ext-8b5df3f6e0.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium_latin-a54b0a8ca6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/roboto_medium-adeec35de0.woff) format('woff');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(core/assets/fonts/NanumGothic-Regular-1d98d61bb3.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(core/assets/fonts/NanumGothic-Regular-5bb7da03f9.woff) format('woff');
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEz0dL_nz-df9811485e.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEzQdL_nz-e45bfee13f.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEzwdL_nz-2e52dce1bb.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEzMdL_nz-e7f80e90d3.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEz8dL_nz-4234724e29.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEz4dL_nz-925c70d087.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url(core/assets/fonts/KFOiCnqEu92Fr1Mu51QrEzAdLw-f8b1df51ba.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc3CsTKlA-c7dcff7355.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc-CsTKlA-e4a706ea31.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc2CsTKlA-e21b677176.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc5CsTKlA-cfcd47e5c2.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc1CsTKlA-4330edbc4b.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc0CsTKlA-32afd4a545.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51TjASc6CsQ-14286f3ba7.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xFIzIFKw-b3e580d221.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xMIzIFKw-8266321ab4.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xEIzIFKw-801b64f75a.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xLIzIFKw-469a78405c.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xHIzIFKw-9780bde87b.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xGIzIFKw-dc3871f486.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(core/assets/fonts/KFOkCnqEu92Fr1Mu51xIIzI-51521a2a8d.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc3CsTKlA-219388f2ea.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc-CsTKlA-6d93e9e294.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc2CsTKlA-20227e28b6.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc5CsTKlA-16714bf780.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc1CsTKlA-85d8c8f63c.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc0CsTKlA-2a730e44d3.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url(core/assets/fonts/KFOjCnqEu92Fr1Mu51S7ACc6CsQ-db4a2a231f.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxFIzIFKw-93fb064094.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxMIzIFKw-d9071d3b07.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxEIzIFKw-fc87f18e82.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxLIzIFKw-36fe9ac379.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxHIzIFKw-ef7b5221d0.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxGIzIFKw-7fa60a10d8.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url(core/assets/fonts/KFOkCnqEu92Fr1MmgVxIIzI-7370c36794.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fCRc4EsA-d69a2de852.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fABc4EsA-a5383450b2.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fCBc4EsA-d4a587dbb0.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fBxc4EsA-f7059272fd.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fCxc4EsA-484cddf4a2.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fChc4EsA-e83b8f976d.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmSU5fBBc4-ef7c6637c6.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu72xKOzY-4743c758a9.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu5mxKOzY-8bb6495276.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu7mxKOzY-182ee6a487.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu4WxKOzY-c1e9793c84.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu7WxKOzY-a8be5b46d0.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu7GxKOzY-455200cb00.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(core/assets/fonts/KFOmCnqEu92Fr1Mu4mxK-479970ffb7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fCRc4EsA-378698af3b.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fABc4EsA-7fd643e63f.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fCBc4EsA-0f80978b9a.woff2)
      format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fBxc4EsA-665639f6a0.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fCxc4EsA-16423fb4da.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA-b1b808436b.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(core/assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4-020c97dc8e.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.react-grid-item.react-grid-placeholder {
  background-color: green;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(220, 214, 210, 0.5);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(110, 110, 110, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(110, 110, 110, 0.8);
}

.ant-tree-node-content-wrapper-open,
.ant-tree-node-content-wrapper-close {
  font-weight: bold;
}

.ant-tree-list-scrollbar {
  width: 12px !important;
  background-color: rgba(220, 214, 210, 0.5);
}

.ant-tree-list-scrollbar-show {
  display: block !important;
}

.ant-tree-list-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: rgba(110, 110, 110, 0.4) !important;
  cursor: initial !important;
}

.ant-tree-list-scrollbar-thumb:active {
  background-color: rgba(110, 110, 110, 0.8) !important;
}

.ant-tree {
  font-size: 12px !important;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #8c8282;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #8c8282;
  border-color: #8c8282;
}

.ant-tree-checkbox-checked::after {
  border-color: #8c8282;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #8c8282;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #8c8282;
  background-color: #8c8282;
}

.ant-checkbox-checked::after {
  border-color: #8c8282;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #8c8282;
}
