.fieldWrapper {
  padding: 0 5px;
  width: 50px;
}

.dialogActionButton {
  min-width: 100px;
  border-radius: 6px;
}

.filterConditionRequired {
  position: absolute;
  bottom: 0;
  color: var(--error-color);
  margin-bottom: 6px;
}
