.sideBar {
  position: relative;
  width: 240px;
  background-color: var(--background-color-3);
  border-bottom: 1px solid var(--neutral-color-2);
  height: auto;
  box-sizing: border-box;
}

.sideBarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuWrapper {
  overflow: auto;
  display: flex;
  height: 100%;
}

.treeView {
  width: 100%;
  background-color: none;
}

.collapseIcon {
  width: 15px;
  height: 20px;
  background-image: url(core/assets/sp_layout.png);
  background-position: -20px -288px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.expandIcon {
  width: 20px;
  height: 20px;
  background-image: url(core/assets/sp_layout.png);
  background-position: 0px -288px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.info {
  position: absolute;
  top: 50%;
  left: 25%;
}

.horizontalDivider {
  height: 1px;
  background: var(--background-color-1);
}

.loadingWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.savedReportsEmpty {
  opacity: 0.5;
  padding: 8px 24px;
  margin-bottom: 8px;
  text-align: center;
}
