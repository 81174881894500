.card {
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  border: 1px solid var(--neutral-color-2);
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
}

.title {
  margin: 0;
  height: 36px;
  background: #eeeded;
  align-items: center;
  color: #5a5a5a;
  padding: 15px 25px 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: 1px solid #dad8d8;
  cursor: pointer;
}

.contentWrapper {
  padding: 24px 18px 24px 120px;
}

.content {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacing {
  margin-left: 86px;
}

.contentTitle {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #5a5a5a;
  display: inline-block;
  width: 140px;
  margin-right: 50px;
}

.uploadLogo {
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  height: 28px;
  padding: 5px 12px 5px 12px;
  background: linear-gradient(to bottom, #fff 0, #e6e6e6 100%);
  font-size: 12px;
  color: #6e6e6e;
}

.uploadLogo:hover {
  border: 1px solid #19a5a0;
}

.input {
  height: 28px;
  padding: 0 14px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border: 1px solid #b4b4b4;
}

.switchButton {
  color: #5a5a5a;
  font-size: 12px;
}

.contentFormat {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 30%;
}

.contentFormatTitle {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #5a5a5a;
  display: inline-block;
  width: 100px;
}

.select {
  height: 28px;
  width: 300px;
  padding: 0 14px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border: 1px solid #b4b4b4;
}

.uploadBtnWrapper {
  margin-left: 50px;
}

.inputLabelWrapper {
  width: 150px;
  display: flex;
}

.inputLabel {
  width: 95px;
}
