.menuBar {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: var(--background-color-2);
  border-bottom: 1px solid var(--neutral-color-2);
}

.navMenu {
  display: flex;
  height: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--font-color);
  padding: 0 19px;
  height: auto;
  cursor: pointer;
  font-weight: 600;
}

.link:global(.active) {
  background: rgba(0, 0, 0, 0.08);
}

.link:hover {
  background: rgba(0, 0, 0, 0.08);
}

.rightSection {
  margin-right: 16px;
}

.redAlertIcon {
  margin-left: 10px;
  width: 20px;
  animation: blinking 1s linear infinite;
  display: none;
}

.showRedAlert {
  display: block;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
