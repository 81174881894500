.btnPrimary {
  color: #fff;
  background: linear-gradient(to bottom, #7ecace 0, #6db9be 100%);
  border: 1px solid var(--primary-color-dark);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 1px rgba(255, 255, 255, 0.3);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.btnPrimary:hover {
  border-color: #415a5a;
}
