.contentWrapper {
  width: 100%;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWrapper {
  margin-bottom: 15px;
}

.inputWrapper:last-child {
  margin-bottom: 0;
}

.inputLabel {
  display: flex;
  width: 65px;
}

.imgLog {
  width: 100%;
  height: 587px;
}

.userInfoWrapper {
  width: 100%;
  margin-top: 25px;
}

.value {
  white-space: wrap;
}

.saveBtn {
  width: 134px;
  text-transform: capitalize;
}
