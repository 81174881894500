.button {
  text-transform: uppercase;
  padding: 0 16px;
  height: 44px;
  color: #868686;
  background: #efefef;
  border: 1px solid #919191;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  box-shadow: inset 0 0 0 1px rgb(255 255 255 / 90%),
    0 2px 4px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button:hover {
  border-color: var(--neutral-color-1);
}

.fullWidth {
  width: 100%;
}

.small {
  height: 34px;
  font-size: 12px;
  text-transform: capitalize;
}

.smallest {
  height: 24px;
  padding: 0 8px;
  font-size: 12px;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.hide {
  visibility: hidden;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.img {
  margin-right: 5px;
}
