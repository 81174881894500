.table {
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid #a49c9e;
  box-sizing: border-box;
  background: #fff;
}

.theadData {
  font-size: 12px;
  color: #ffffff;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #8c828c;
  background-color: #a49c9e;
  text-align: center;
  height: 45px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.theadData:first-child {
  border-left: none;
}

.resizer {
  width: 5px;
  height: 100%;
  position: absolute;
  right: -4px;
  z-index: 1;
}

.theadData:last-child .resizer {
  width: 3px;
  right: 0;
}

.theadDataValue {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px;
}

.tbodyRow {
  width: 100%;
  background-color: #fff;
}

.tbodyData {
  background-color: #fff;
}

.tbodyRow:nth-child(even) .tbodyData {
  background-color: #fafafa;
}

.tbodyRow:hover .tbodyData {
  background-color: #f7f2c0;
}

.tbodyData {
  text-align: center;
  padding: 8px;
  color: #5a5a5a;
  font-size: 12px;
  min-height: 35px;
  box-sizing: border-box;
  word-wrap: break-word;
}

.tbodyRow:not(:last-child) .tbodyData {
  border-bottom: 1px solid #ebebeb;
}

.table .infoWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading,
.noResult {
  position: absolute;
}

.table .image {
  /* Aspect ratio 53:71, https://calculateaspectratio.com */
  width: 53.75px;
  height: 72px;
  object-fit: cover;
  display: flex;
}

.moreGroups {
  background-color: #999;
  padding: 2px 6px;
  color: #fff;
  border-radius: 16px;
  display: inline-flex;
}

.sorting {
  color: #5b5b5b;
  position: absolute;
  right: 6px;
}

.authModeCell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.descriptionIcons {
  display: flex;
  align-items: center;
  border-right: 1px solid #ebebeb;
  padding-right: 5px;
  margin-right: 5px;
}

.icon {
  height: 12px;
}

.nameSpan {
  margin: 0 5px;
}

.noPadding {
  padding: 0;
}
