.tableWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 20px;
}

.image {
  border-radius: 5px;
  border: solid 1px var(--neutral-color-2);
  width: 106px;
  height: 142px;
}

.userName,
.userId {
  font-size: 18px;
}

.userId {
  margin: 20px 0 5px 0;
}

.userName {
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
