input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dynamicDateTimeWrapper {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  font-size: 14px;
  height: 260px;
}

.currentDateWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.startWrapper,
.endWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.days,
.hours,
.minutes {
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  border: 1px solid var(--neutral-color-2);
  padding: 0 14px;
  outline: none;
  height: 28px;
}

.days {
  width: 60px;
  margin: 0 5px;
}

.hours,
.minutes {
  width: 45px;
}

.inputError {
  border: 1px solid red;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.validationText {
  color: red;
}
