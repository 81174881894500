.searchMask {
  position: relative;
  padding: 9px 12px;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #ebebeb;
  background: #f9f9f9;
  color: var(--neutral-color-1);
  font-weight: 700;
  height: 36px;
}

.searchFieldWrapper {
  padding: 5px 4px;
  height: 36px;
  background: #f9f9f9;
}

.searchField {
  color: #5a5a5a;
  font-weight: 400;
  line-height: 26px;
  border: 1px solid #b4b4b4;
  background: #f0f0f0;
}

.searchField input {
  font-size: 14px;
  padding: 2px 5px;
}

.fieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}

.bulletItem {
  padding-right: 9px;
}

.customReportDropdown {
  top: 78px;
}

.iconSearch {
  position: absolute;
  top: 11px;
  right: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url(core/assets/sp_icon_button.png);
  background-repeat: no-repeat;
  background-position: 0 -105px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.iconSearch:hover {
  background-position: -32px -105px;
}

.iconClose {
  width: 10px;
  height: 10px;
  background-color: transparent;
  background-image: url(core/assets/btn_search_close.png);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}
