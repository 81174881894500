.checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 230px;
  display: flex;
  justify-content: center;
}

.formControlLabel {
  font-size: 12px;
  vertical-align: middle;
}
