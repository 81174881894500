.dialogActionButton {
  min-width: 100px;
  border-radius: 6px;
}

.loadingWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.step {
  display: none;
}

.activeStep {
  display: block;
}

.filterConditionRequired {
  position: absolute;
  bottom: 0;
  color: var(--error-color);
  margin-bottom: 6px;
}
