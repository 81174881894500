.paper {
  max-width: 765px !important;
  border-radius: 10px !important;
  border: 2px solid var(--neutral-color-3);
}

.title {
  display: flex;
  justify-content: space-between;
  background: var(--neutral-color-4);
  align-items: center;
  color: var(--background-color-3);
  box-sizing: border-box;
  padding: 0 24px;
  height: 42px;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 7px 7px 0 0;
}

.buttonIconClose {
  width: 14px;
  height: 14px;
  background: url(core/assets/popup_close.png);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

.mainContent {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px !important;
  overflow-y: visible !important;
}

.actions {
  justify-content: center !important;
  background: var(--background-color-2);
  height: 63px;
  box-sizing: border-box;
  border-top: 1px solid var(--background-color-1);
  border-radius: 0 0 7px 7px;
}
