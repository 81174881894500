.dialogActionButton {
  min-width: 100px;
}

.loadingWrapper {
  display: flex;
  width: 550px;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.filterConditionRequired {
  color: var(--error-color);
  margin-bottom: 0;
}
