.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageTitleWrapper {
  display: flex;
  align-items: center;
}

.btnBack {
  width: 40px;
  height: 35px;
  background-image: url(core/assets/sp_button.png);
  background-position: 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  margin-right: 8px;
}

.btnBack:hover {
  background-position: -80px 0;
}

.titleStyle {
  margin: 0;
  font-weight: bold;
  font-size: 2em;
  color: var(--neutral-color-3);
  margin-bottom: 2px;
}
