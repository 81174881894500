.fieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.field {
  height: 28px;
  padding: 0 14px;
  margin: 0;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: 400;
  line-height: 27px;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--neutral-color-2);
  box-sizing: border-box;
  background: #ffffff;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  display: inline-block;
}

.field:focus {
  border-color: var(--color-primary-dark);
}

.customSelectField {
  position: absolute;
  background-image: url('core/assets/sp_form.png');
  background-repeat: no-repeat;
  background-position: 0 -34px;
  right: 14px;
  top: 11px;
  width: 10px;
  height: 6px;
  cursor: pointer;
}

.arrowActive {
  background-position: -10px -34px;
}

.emboss {
  border: 1px solid #b4b4b4;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.08);
  background: linear-gradient(to top, #e6e6e6, #fff);
}

.emboss:focus {
  border: 1px solid #19a5a0;
}

.hint {
  text-align: left;
  margin-top: 3px;
  padding: 0 14px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.error {
  color: var(--error-color);
}

.dropdownContent {
  position: absolute;
  width: 100%;
  top: 28px;
  left: 0;
  background-color: #fff;
  border: 1px solid var(--neutral-color-2);
  border-radius: 4px;
  z-index: 1;
}

.treeWrapper {
  max-height: 15em;
  overflow-y: scroll;
}

.treeView {
  width: 100%;
  background-color: none;
}

.collapseIcon {
  width: 15px;
  height: 20px;
  background-image: url(core/assets/sp_layout.png);
  background-position: -20px -288px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.expandIcon {
  width: 20px;
  height: 20px;
  background-image: url(core/assets/sp_layout.png);
  background-position: 0px -288px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.hide {
  display: none;
}

.searchField {
  background: #f0f0f0;
  border-bottom: 1px solid #b4b4b4;
}

.labelWrapper {
  display: flex;
  align-items: center;
}

.label {
  margin-top: 3px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notFound {
  padding-left: 8px;
  height: 35px;
  display: flex;
  align-items: center;
}

.searchResultWrapper {
  margin-bottom: 0;
}

.searchResultItem {
  padding: 6px 14px;
  cursor: pointer;
  list-style-type: none;
}

.searchResultItem:hover {
  background-color: #f7f2c0;
}
