.actionButton {
  min-width: 100px;
  border-radius: 6px;
}

.wrapper {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.successIcon {
  margin-right: 20px;
}

.message {
  margin: 0;
  font-size: 14px;
}
