.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color-3);
}

.wrapper {
  max-width: 350px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  display: flex;
}
