.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
  transform-origin: 0 0;
  white-space: nowrap;
}

.checkpointWrapper {
  text-align: center;
}

.checkpointText {
  fill: var(--neutral-color-8);
  font-weight: 500;
}

.checkpointItemsWrapper {
  display: inline-flex;
  overflow: auto;
}

.checkpointImageWrapper {
  text-align: center;
  margin: 10px;
}

.checkpointMessageWrapper {
  display: inline;
  margin: 10px;
  overflow-wrap: break-word;
  background: var(--background-color-3);
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.checkpointTextWrapper {
  margin: 10px;
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 10px 0;
}

.actionButtonsWrapper button {
  margin-left: 5px;
}

.actionButton {
  min-width: 130px;
  height: 28px;
}

.playPauseBtn {
  background: linear-gradient(to bottom, #6b5e62 0, #524649 100%);
  border: 1px solid #594b4f;
  color: #fff;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
}

.checkpointNoData {
  font-size: 16px;
  color: var(--neutral-color-4);
}

.deletedInfoContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 175%;
}

.deletedInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconDeleted {
  width: 82px;
  height: 82px;
}

.deletedInfo {
  margin-top: 20px;
  color: #707070;
}
