.table {
  border: 1px solid #a49c9e;
  box-sizing: border-box;
  background: #fff;
}

.theadData {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #8c828c;
  background-color: #a49c9e;
  text-align: center;
  height: 38px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.theadData:first-child {
  border-left: none;
}

.theadDataValue {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px;
}

.tbodyRow {
  width: 100%;
  background-color: #fff;
}

.dragDropRow {
  cursor: move;
}

.tbodyData {
  background-color: #fff;
  text-align: left;
  padding: 8px;
  color: #5a5a5a;
  font-size: 12px;
  min-height: 35px;
  box-sizing: border-box;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.tbodyRow:nth-child(even) .tbodyData {
  background-color: #fafafa;
}

.tbodyRow:hover .tbodyData {
  background-color: #f7f2c0;
}

.tbodyRow:not(:last-child) .tbodyData {
  border-bottom: 1px solid #ebebeb;
}

.infoWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  position: absolute;
}

.selectionCheckbox {
  accent-color: #8c8282;
}
