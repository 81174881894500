.wrapper {
  display: flex;
}

.selectRowsPerPage {
  outline: none;
  margin: 0 4px;
  background: linear-gradient(
    to bottom,
    var(--background-color-3) 0,
    var(--neutral-color-6) 100%
  );
  box-shadow: inset 0 0 0 1px rgb(255 255 255 / 80%),
    0 1px 2px 1px rgb(0 0 0 / 10%);
  border: 1px solid var(--neutral-color-2);
  border-radius: 4px;
  height: 28px;
  line-height: 26px;
  color: var(--neutral-color-8);
  cursor: pointer;
  font-size: 12px;
  padding: 0 5px;
}

.selectRowsPerPage:hover {
  border-color: var(--primary-color);
}
