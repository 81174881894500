.containerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 500px;
  min-height: 250px;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 38px;
}

.label {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  width: 150px;
}

.checkboxHeader {
  padding-right: 5px;
}
.checkboxEveryPage {
  padding-right: 10px;
}

.bulletItem {
  padding-right: 9px;
}

.buttonAction {
  min-width: 100px;
  border-radius: 6px;
}

.formControlLabel {
  font-size: 12px;
}
