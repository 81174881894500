.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.message {
  color: var(--neutral-color-1);
  text-transform: capitalize;
}

.liteMessage {
  font-size: 16px;
}
