.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
  transform-origin: 0 0;
  white-space: nowrap;
}

.widgetContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alarmEventWrapper {
  min-height: 50px;
  max-height: 105px;
  min-width: 127px;
  max-width: 221px;
  color: #707070;
  line-height: 1.13;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarmWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}

.alarmEvent {
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 400;
  line-height: 1.38;
  justify-content: center;
  padding: 0 10px;
}

.doorStatusWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  bottom: 30%;
  left: 0;
  right: 0;
  margin: auto;
}

.doorLabel {
  margin-top: -25px;
}

.button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
}

.deletedInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  bottom: 40%;
  left: 0;
  right: 0;
  margin: auto;
}

.iconDeleted {
  width: 82px;
  height: 82px;
}

.deletedInfo {
  margin-top: 20px;
  color: #707070;
}
