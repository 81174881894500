.actionButton {
  min-width: 100px;
  border-radius: 6px;
}

.defaultColumnButton {
  box-shadow: inset 0 0 0 1px rgb(255 255 255 / 80%),
    0 1px 2px 0 rgb(0 0 0 / 15%);
  background: linear-gradient(to bottom, #fbfbfb 0, #ebebeb 100%);
  color: #594b4f;
  text-transform: none;
  letter-spacing: normal;
  margin-left: auto;
  margin-bottom: 8px;
}

.table :global(.table-body) {
  max-height: 250px;
  overflow: auto;
}

.table :global(.body-cell) {
  justify-content: center;
}
