.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
  transform-origin: 0 0;
  white-space: nowrap;
}

.counterWrapper {
  text-align: center;
}

.counterText {
  color: var(--neutral-color-8);
  font-weight: 500;
}

.textBox {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--neutral-color-8);
  font-weight: 500;
}

.preLine {
  white-space: pre-line;
}

.preLineWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preLineBox {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--neutral-color-8);
  font-weight: 500;
}

.noDataWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: var(--neutral-color-4);
}
