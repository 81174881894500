.button {
  font-size: 14px;
  width: 101px;
  border-radius: 6px;
}

.btnRadius {
  border-radius: 6px;
}

.inputLabel {
  display: flex;
  width: 100px;
  height: 17px;
}

.warningMsg {
  padding: '7px 13px 7px 18px';
  color: '#9d870e';
  border: '1px solid #ede381';
  background: '#f7f2c0';
  white-space: 'nowrap';
  top: '-36px';
  position: 'absolute';
}

.guideMsg {
  color: var(--neutral-color-1);
  line-height: 0;
}
