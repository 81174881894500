.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
}

.counterWrapper {
  text-align: center;
}

.counterText {
  fill: var(--neutral-color-8);
  font-weight: 500;
}

.imageWrapper {
  min-height: 210px;
}

.checkpointImage {
  width: 160px;
  padding: 10px;
  height: 205px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
