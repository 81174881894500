.fontColor {
  color: var(--neutral-color-3);
}

.textAlignCenter {
  text-align: center;
}

.btnAddWidget {
  height: 44px;
  width: 170px;
  border-radius: 6px;
  font-size: 18px;
}

.title {
  font-size: 26px;
  margin: 0;
}

.info {
  font-size: 18px;
  margin: 0;
}

.divider {
  height: 27px;
}
