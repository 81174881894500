.headerTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headerWrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.headerListItemWrapper {
  line-height: 1.6;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}

.headerListItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonActions {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.buttonActionsRight {
  display: flex;
}

.buttonActionsRight button {
  margin-left: 10px;
}

.actionButton {
  min-width: 130px;
  height: 28px;
}

.iconEditWrapper {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.iconEdit {
  width: 14px;
  height: 14px;
  background-color: transparent;
  background-image: url(core/assets/sp_icon_button.png);
  background-repeat: no-repeat;
  background-position: 0 -17px;
  border: none;
  cursor: pointer;
}

.fieldWrapper {
  display: flex;
  align-items: center;
}
