.title {
  margin: 0;
  background: #eeeded;
  align-items: center;
  color: var(--neutral-color-1);
  padding: 16px 25px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: 1px solid #dad8d8;
  display: flex;
  font-size: 1.25em;
  font-weight: 700;
  height: 0;
}
