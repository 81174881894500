.widgetTextarea {
  width: 400px;
  height: 150px;
}

.inputLabel {
  display: flex;
  width: 140px;
  height: 17px;
}

.icon {
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.doorGroupIcon {
  background-image: url(core/assets/sp_layout.png);
  background-position: -120px -288px;
}

.doorIcon {
  background-image: url(core/assets/sp_layout.png);
  background-position: -487px -288px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark::before {
  content: '';
  background-position: 0 0;
  background-image: url(core/assets/sp_form.png);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.checkbox:checked + .checkmark::before {
  background-position: -16px 0;
}

.warningTooltip {
  position: absolute;
  right: 0;
  top: -23px;
  padding: 1px 7px;
  color: #9d870e;
  border: 1px solid #ede381;
  background: #f7f2c0;
  white-space: nowrap;
}

.widgetTextarea {
  resize: none;
}
