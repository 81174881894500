.card {
  border: 1px solid #a49c9e;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
