.timeInput {
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  border: 1px solid var(--neutral-color-2);
  padding: 0 14px;
  outline: none;
  height: 28px;
  width: 45px;
}

.separated {
  margin: 0 5px;
}
