.iconSearchTransparent {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: url(core/assets/icon_dv_advancedsearch.png);
  background-position: left 4px;
  background-color: transparent;
}

.searchField {
  padding: 3px 0px;
}

.searchField input {
  font-size: 14px;
  padding: 2px 5px;
}

.btnFilters {
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  width: 348.88px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  border: 1px solid var(--neutral-color-2);
  left: 372px;
  top: 24px;
  padding: 5px 10px;
}

.fieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}

.bulletItem {
  padding-right: 9px;
}

.customReportDropdown {
  top: 78px;
}

.iconSearch {
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url(core/assets/sp_icon_button.png);
  background-repeat: no-repeat;
  background-position: 0 -105px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.iconFilter {
  width: 15px;
}
