.tableWrapper {
  overflow: hidden;
  box-sizing: border-box;
}

.table {
  border: 1px solid var(--neutral-color-8);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.userInfo {
  display: flex;
  padding: 25px 0 25px 0;
}

.userInfoContainer {
  width: 100%;
  padding: 15px 40px;
}

.userNameWrapper {
  margin-bottom: 10px;
}

.userName {
  font-size: 22px;
  font-weight: 700;
}

.userId {
  font-size: 18px;
  margin-left: 10px;
}

.userInfoWrapper {
  display: flex;
}

.userInfoHeadRow,
.userInfoDataRow {
  display: flex;
  flex-direction: column;
}

.userInfoDataRow {
  width: 100%;
}

.userInfoHead,
.userInfoData {
  font-size: 15px;
  padding: 5px 0;
  margin-bottom: 5px;
}

.userInfoHead:last-child,
.userInfoData:last-child {
  margin-bottom: none;
}

.userInfoHead {
  font-weight: 700;
}

.photoWrapper {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 142px;
}

.photo {
  border-radius: 5px;
  width: 106px;
  height: 142px;
}

.userStatus,
.userAccessGroups {
  font-size: 15px;
  overflow: hidden;
}

.userStatusHeadRow,
.accessGroupHead {
  color: var(--neutral-color-10);
  background-color: var(--neutral-color-7);
}

.userStatusDataRow {
  height: 80px;
}

.userStatusHead,
.userStatusData {
  text-align: center;
  padding: 10px;
}

.userStatusHead {
  border-right: solid 1px var(--background-color-3);
  font-weight: 700;
}

.userStatusHead:last-child {
  border-right: none;
}

.userStatusData {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accessGroupHead {
  padding: 10px 40px;
  font-weight: 700;
}

.accessGroupData {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 20px 40px;
}
