.containerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding: 10px 0;
}

.paginationWrapper {
  display: flex;
}

.btn {
  background-image: url(core/assets/sp_button.png);
  background-color: transparent;
  height: 25px;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn:disabled {
  cursor: default;
}

.btnGoFirst {
  background-position: -1px -349px;
  width: 30px;
  margin: 3px 2px 0 0;
}

.btnGoFirst:disabled {
  background-position: -58px -349px;
}

.btnPrev {
  background-position: -31px -349px;
  width: 27px;
  margin: 3px 0 0;
}

.btnPrev:disabled {
  background-position: -88px -349px;
}

.pagingInfo {
  margin: 3px 8px;
}

.pageInput {
  width: 34px;
  max-width: 100px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid var(--neutral-color-2);
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  outline: none;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 700;
  color: rgb(90, 90, 90);
}

.pagingInfoSeparator {
  margin: 0 3px;
  height: 22px;
  color: var(--neutral-color-1);
  font-size: 12px;
  font-weight: 700;
}

.totalPage {
  font-size: 12px;
  font-weight: 700;
  color: var(--neutral-color-1);
}

.btnNext {
  background-position: -1px -374px;
  width: 27px;
  margin: 3px 2px 0 0;
}

.btnNext:disabled {
  background-position: -58px -374px;
  width: 27px;
}

.btnGoLast {
  background-position: -28px -374px;
  width: 30px;
  margin: 3px 8px 0 0;
}

.btnGoLast:disabled {
  background-position: -85px -374px;
  width: 28px;
}
