.menuItem {
  cursor: pointer;
  padding: 8px 16px !important ;
  color: var(--primary-color-dark) !important;
  border-bottom: 1px solid #ebebeb !important;
  font-size: 0.917em !important;
  height: 31px;
  line-height: 1;
}

.menuItem:hover {
  background: #dcf1f2 !important;
}
