.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.section {
  display: flex;
}

.section > *:not(:last-child) {
  margin-right: 4px;
}

.actionButton {
  min-width: 130px;
  height: 28px;
}
