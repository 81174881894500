.fieldWrapper {
  display: flex;
  flex-direction: column;
}

.field {
  height: 28px;
  padding: 0 14px;
  margin: 0;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: 400;
  line-height: 27px;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--neutral-color-2);
  box-sizing: border-box;
  background: #ffffff;
}

.field:focus {
  border-color: var(--color-primary-dark);
}
