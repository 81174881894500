.fieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.spanFieldWrapper {
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
}

.field {
  height: 28px;
  padding: 0px 33px 0 14px;
  margin: 0;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: 400;
  line-height: 27px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.8),
    0 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--neutral-color-2);
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  position: inherit;
}

.field:focus {
  border-color: var(--color-primary-dark);
}

.customSelectField {
  position: absolute;
  background-image: url('core/assets/sp_form.png');
  background-repeat: no-repeat;
  background-position: 0 -34px;
  right: 14px;
  top: 11px;
  width: 10px;
  height: 6px;
  cursor: pointer;
}

.arrowActive {
  background-position: -10px -34px;
}

.emboss {
  border: 1px solid #b4b4b4;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.08);
  background: linear-gradient(to top, #e6e6e6, #fff);
}

.emboss:focus {
  border: 1px solid #19a5a0;
}

.dropdownContent {
  position: absolute;
  width: 100%;
  top: 25px;
  left: 0;
  background-color: #fff;
  border: 1px solid var(--neutral-color-2);
  z-index: 2;
}

.optionList {
  margin: 0;
  padding: 0;
}

.optionMaxHeight {
  max-height: 15em;
  overflow-y: auto;
}

.option {
  list-style-type: none;
  padding: 6px 14px;
  cursor: pointer;
}

.option:hover {
  background-color: #f7f2c0;
}

.optionNone {
  background: #dcf1f2;
  pointer-events: none;
}

.hint {
  text-align: left;
  margin-top: 3px;
  padding: 0 14px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.error {
  color: var(--error-color);
}

.dropdownContentWrapper {
  height: 105px;
  overflow-y: scroll;
}

.customZIndex {
  z-index: 3;
}

.displaySelected {
  overflow: hidden;
  text-wrap: nowrap;
}

.disabled {
  background: #e6e6e6;
  color: #b4b4b4;
  border: 1px solid #c5c5c5 !important;
  box-shadow: none;
}

.arrowDisabled {
  opacity: 0.3;
}
