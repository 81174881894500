.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
  transform-origin: 0 0;
  white-space: nowrap;
}
