.actionButton {
  min-width: 100px;
  border-radius: 6px;
  background: #594b4f;
  color: #fff;
  margin-left: 5px;
  border: 0;
  box-shadow: none;
}

.message {
  margin: 0;
}

.alertWrapper {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.alertIcon {
  background-image: url('core/assets/icon_messager.png');
  background-repeat: no-repeat;
  width: 52px;
  height: 52px;
  margin-right: 20px;
}
