.dialogActionButton {
  min-width: 100px;
  border-radius: 6px;
}

.loadingWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.step {
  visibility: hidden;
}

.activeStep {
  display: block;
  visibility: visible;
}

.filterConditionRequired {
  color: var(--error-color);
  margin-bottom: 0;
}

.contentCustomStyles {
  padding: 10px !important;
}
