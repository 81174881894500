.icon {
  fill: var(--info-color);
}

.tooltip {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
