.buttonStyle {
  width: 81px;
  height: 25px;
  background-color: var(--neutral-color-12);
  color: var(--font-color-2);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  border: none;
  box-shadow: none;
  padding: 5px 8px;
}

.buttonStyle:hover {
  background-color: var(--primary-color-2);
}

.activeBackground {
  background-color: var(--primary-color-2);
}

.dropdownContent {
  position: absolute;
  width: 119px;
  border: solid 1px #e1ebeb;
  margin: 0 0 30px;
  padding: 0;
  background-color: var(--background-color-3);
  right: 0;
  bottom: 10px;
  z-index: 5;
}

.unorderedListStyle {
  padding: 0;
  margin: 0;
  color: #1aa69f;
}

.listStyle {
  list-style-type: none;
  border-bottom: 1px solid #e1ebeb;
  padding: 3px 0 3px 17px;
  cursor: pointer;
}

.listStyle:hover {
  background-color: #f7f2c0;
}

.listStyle:last-child {
  border: none;
}
