.searchFieldWrapper {
  display: flex;
}

.searchInput {
  margin: 0 2px 0 0;
  width: 250px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border: 1px solid var(--neutral-color-2);
  border-radius: 4px;
  outline: none;
}

.goBtn {
  border-radius: 4px;
  padding: 6px 0;
  height: 28px;
  width: 40px;
  font-size: 12px;
  text-transform: capitalize;
}
