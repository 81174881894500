.fieldWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 245px;
}

.fieldWrapper span {
  margin-left: 8px;
}

.inputPrefix {
  margin-right: 8px;
  margin-left: 0 !important;
}
