.staticDatePickerWrapper {
  min-width: 182px;
  width: 182px;
}

.staticDatePickerWrapper div:first-of-type {
  min-width: unset;
}

.staticDatePickerWrapper :global(.MuiCalendarPicker-root) {
  width: 182px !important;
  margin: 0 !important;
}

.staticDatePickerWrapper :global(.MuiCalendarPicker-root div:first-of-type) {
  margin-top: unset;
  margin-bottom: unset;
}

.staticDatePickerWrapper
  :global(.MuiCalendarPicker-root div:first-of-type button.MuiIconButton-root) {
  height: 24px;
  width: 10px;
}

.staticDatePickerWrapper :global(.PrivatePickersSlideTransition-root) {
  font-size: 12px;
  min-height: 145px !important;
}

.staticDatePickerWrapper
  :global(.PrivatePickersFadeTransitionGroup-root div[role='cell']) {
  width: 24px;
  height: 22px;
}

.staticDatePickerWrapper
  :global(.PrivatePickersFadeTransitionGroup-root .MuiButtonBase-root) {
  width: 22px;
  height: 22px;
}

.staticDatePickerWrapper
  :global(.PrivatePickersFadeTransitionGroup-root .MuiTypography-root) {
  width: 24px;
  height: 24px;
  margin: 0;
}

.staticDatePickerWrapper :global(div[role='presentation']) {
  margin-left: -9px;
  font-size: 12px;
}

.label {
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 26px;
}

.inputWrapper {
  display: flex;
  justify-content: center;
  width: 180px;
  margin-top: 5px;
}

.inputWrapper :global(.MuiOutlinedInput-notchedOutline) {
  height: 28px;
  top: 0;
}

.inputWrapper :global(.MuiOutlinedInput-notchedOutline legend) {
  display: none;
}

.inputWrapper :global(.MuiOutlinedInput-notchedOutline) {
  height: 28px;
  top: 0;
}

.inputWrapper :global(.MuiOutlinedInput-notchedOutline legend) {
  display: none;
}

.dateInput,
.timeInput {
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  border: 1px solid var(--neutral-color-2);
  outline: none;
  height: 28px;
}

.dateInput {
  width: 95px;
}

.timeInputWrapper {
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 80px;
}

.timeInputWrapper :global(.MuiButtonBase-root) {
  padding: 0;
}

.timeInputWrapper :global(.MuiButtonBase-root) {
  padding: 0;
}

.textFieldWrapper {
  display: block;
}

.timeInput {
  width: 80px;
}
