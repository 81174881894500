.actionButton {
  min-width: 100px;
  border-radius: 6px;
  background: var(--neutral-color-5);
  border: none;
  box-shadow: none;
}

.wrapper {
  display: flex;
}

.informationIcon {
  margin-right: 20px;
}

.message {
  margin: 0;
}
