.mainWrapper {
  display: flex;
}

.spacer {
  width: 16px;
}

.horizontalDivider {
  height: 1px;
  background: var(--neutral-color-2);
}

.boxWrapper {
  border: 1px solid var(--neutral-color-2);
  width: 366px;
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.treeWrapper {
  flex: 1;
  overflow: auto;
  display: flex;
}

.treeItem :global(.MuiTreeItem-content) {
  box-sizing: border-box;
}

.treeItem :global(.MuiTreeItem-content.Mui-focused) {
  background-color: transparent;
}

.treeItem :global(.MuiTreeItem-content:hover) {
  background-color: rgba(0, 0, 0, 0.04);
}

.treeItem :global(.MuiTreeItem-group) {
  margin-left: 0;
}

.boxResult {
  overflow-y: auto;
  height: 100%;
  margin: 0;
  padding: 0;
}

.selectedItem {
  height: 38px;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.selectedItemText {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
}

.btnDelete {
  width: 10px;
  height: 14px;
  background-image: url(core/assets/icon_delete.png);
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
}
