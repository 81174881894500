.button {
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.reloadButton {
  background-image: url(core/assets/btn_refresh_icon.png);
  width: 17px;
  height: 17px;
}

.settingsButton {
  background-image: url(core/assets/btn_search_option.png);
  width: 19px;
  height: 19px;
}

.infoWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  position: absolute;
}
