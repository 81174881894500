.viewColumnContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.viewImageBtn {
  background-image: url('core/assets/imagelog_a2.png');
  background-repeat: no-repeat;
  background-color: transparent;
  width: 25px;
  height: 20px;
  cursor: pointer;
  border: none;
}
