.backButton {
  width: 40px;
  height: 35px;
  background-image: url(core/assets/sp_button.png);
  background-position: 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  margin-right: 8px;
}

.backButton:hover {
  background-position: -80px 0;
}
