.layoutWrapper {
  display: flex;
  flex-direction: column;
}

.layout {
  width: 100%;
  flex: 1;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
