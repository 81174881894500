.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title {
  color: var(--neutral-color-1);
  text-transform: uppercase;
  letter-spacing: 0.5;
}
