.buttonStyle {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.deleteButton {
  background-image: url(core/assets/report_icon_delete.png);
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 15px;
  height: 17px;
}

.editButton {
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url(core/assets/report_icon_modify.png);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

.decorative {
  background-color: #19a5a0;
  position: absolute;
  width: 100%;
  height: 16px;
  left: 0;
  bottom: 0;
}
