.widgetTitleWrapper {
  text-align: center;
  padding-top: 0.5em;
}

.widgetTitle {
  color: var(--neutral-color-8);
  font-size: 18px;
  margin-bottom: 0;
  transform-origin: 0 0;
  white-space: nowrap;
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 10px 0;
}

.actionButtonsWrapper button {
  margin-left: 5px;
}

.actionButton {
  min-width: 130px;
  height: 28px;
}

.playPauseBtn {
  background: linear-gradient(to bottom, #6b5e62 0, #524649 100%);
  border: 1px solid #594b4f;
  color: #fff;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
}

.clearBtn {
  color: #594b4f;
  background: linear-gradient(to bottom, #fbfbfb 0, #ebebeb 100%);
  border: 1px solid #b4b4b4;
}
